.appBarSize{
    width: 100%;
  }
  
  .buttonCircleTeal{
    position: relative;
    left: 0rem;
    right: 0rem;
    top: 0rem;
    bottom: 0rem;
    height:2.9rem;
    width:12rem;
  
    border: 2px solid #242828;
    box-sizing: border-box;
    border-radius: 2.3rem;
    margin-top: 2rem;
    /* margin-left:2rem; */
    background-color: #2CC9B6;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .buttonCircleChange{
    /* left: 0rem !important;
    right: 0rem !important;
    top: 0rem !important;
    bottom: 0rem !important; */
    height:2.9rem !important;
    width:12rem !important;
    display:flex;
    border: 2px solid #242828 !important;
    box-sizing: border-box !important;
    border-radius: 2.3rem !important;
    margin-top: 2rem;
    /* margin-left:2rem; */
    background-color: #2CC9B6 !important;
    color:white !important;
    align-items: center;
    text-align: center;
    justify-content: center;
    
  }

  .buttonCircle{
    /* left: 0rem !important;
    right: 0rem !important;
    top: 0rem !important;
    bottom: 0rem !important; */
    display:flex;
    height:2.9rem !important;
    width:12rem !important;

    border: 2px solid #242828 !important;
    box-sizing: border-box !important;
    border-radius: 2.3rem !important;
    margin-top: 2rem;
    /* margin-left:2rem; */
    background-color: white !important;
    color:black !important;
    justify-content: center;
  }
  
  .buttonText{
    position: relative;
    /* left: 0.3rem;
    right: 0.3rem; */
    top: 0.5rem;
    bottom: 0.4rem;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color:black;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .homeHeader{
    display:flex;
    flex-direction: row;
  }

  .buttonHeader{
    display:flex;
    margin-left: 25rem;
  }
  
  .sideImage{
    width: 50rem;
    height: 25rem;
    margin-top:3rem;
    margin-right:2rem;
  }

  .serviceStatement{
    margin-top:3rem;
    margin-right:2rem;
  }

  .homeRect{
    width: 30rem;
    height:18.75rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .homeCircle{
    width:13.18rem;
    height:13.18rem;
    left:18.81rem;
    right:30.18rem;
    border-radius: 10rem;
    align-items: center;
  }

  .textBox{
    width: 27rem !important;
    height: 2.75rem !important;
    /* left: 7.8125rem !important;
    top: 24rem !important; */

    background: #FFFFFF !important;
    border: 0.0625rem solid #000000 !important;
    box-sizing: border-box !important;
    border-radius: 6.25rem !important;
    padding-left: 1rem !important;
    text-align: left;
    align-items: center;
    align-content: center;
  }

  .moduleCardFormat{
    width: 72rem;
    /* height: 11.3rem; */
    left: 8.9rem;
    top: 23.3rem;
    border-radius: 1.3rem;
    margin-bottom: 2rem;
  }

  .moduleCardP{
    padding-right: 1rem;
    font-weight: 500;
  
    transform: translate(5%,30%);
  }

  .purpleCircle{
    width: 3.125rem;
    height: 3.125rem;
    background: #7E28C1;
    border-radius: 10rem;
    text-align: center;
    align-content: center;
    align-items: center;
    padding-top:0.5rem;
    /* margin-bottom: 1rem; */
    margin-left: 2rem;
  }

  .numberText{
    width: 1.4rem;
    height: 1.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.68rem;
    color: #FFFFFF;
  }

  .careerScroll{
    width: 70rem;
    height: 16rem;
    left: 18rem;
    top: 24rem;
    border: 0.2rem solid ;
    border-radius: 1rem;
    margin-left:7rem;
    margin-top:1rem;
    display: grid;
    grid-template-columns: 30rem 30rem;
  }

  .connectTile{
  width: 15rem;
  height: 20rem;
  /* left: 69rem;
  top: 88rem; */
  background: #FFFFFF;
  border: 0.2rem solid gray;
  border-radius: 3rem;
  text-align: center;

  /* margin: '2rem'; */
  /* padding: '2rem'; */
  align-content: center;
  align-items: center; 
  justify-items: center;
  /* margin-right:1rem; */
  }

  .circleAvatar{
    width: 7rem;
    height: 7rem;
    /* background: #7E28C1; */
    border-radius: 4rem;
    /* padding-top:0.5rem; */
    /* margin-bottom: 1rem; */
    /* align-content: center;
  align-items: center; */
    /* margin-left: 3rem; */
    margin-top: 1rem;
  }

  .textBoxWarning{
    width: fit-content;
    height: 2.75rem;
    background: #FFFFFF;
    border: 0.2rem solid red;
    box-sizing: border-box;
    border-radius: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    align-items: center;
    align-content: center;
    font-size: 1.3rem;
    color:red;
    margin-bottom: 2rem;
  }

  .buttonCircleConnect{
    height:2.9rem;
    width:12rem;
    border: 2px solid gray;
    box-sizing: border-box ;
    border-radius: 2.3rem ;
    background-color: white;
    color:black;
    font-size: 1.3rem;
    margin-left: 1.3rem;
    text-align: center;
  }

  .contentPaperSize{
    width: 90rem;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    margin-left: 3rem;
  }

  .tabPaperSize{
    width: fit-content;
    height: 30rem;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  }

  .moduleSlides{
    position: relative; 
    width: 100%; 
    height: 0; 
    padding-top: 56.2500%;
    padding-bottom: 48px; 
    box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
    margin-top: 1.6em; 
    margin-bottom: 0.9em; 
    overflow: hidden;
    border-radius: 8px; 
    will-change: transform;
  }

  .moduleSlidesContent{
    position: absolute; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0; 
    border: none; 
    padding: 0;
    margin: 0;
  }

  .personalProfileCalendar{
    height:fit-content;
    width: fit-content;
    margin-left: 1.5rem;
  }

  .arrowOnboarding{
    position: absolute;
    width: 65.88px;
    height: 36px;

    background: #FFFFFF;
  }

  .bioTextBox{
    width: 773px;
height: 108px;
left: calc(50% - 773px/2 + 215.5px);
top: 809px;

background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 10px;
  }


  .connectTabPaperSize{
    width: fit-content;
    height: 10rem;

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  }

  .listItemLabel {
    font-size: 3rem;
    font-weight: 100;
  }

  .avatarPosition{
    align-items: center;
    justify-content: center;
    align-content: center;
    width:3rem;
    height:3rem;
  }